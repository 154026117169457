
import machine1 from "../images/låda-grey-orange-2.png"
import webApp from "../images/web-app-51.png"
import berryPriceLogo from "../images/berryprice-logo.svg";

export default function Products() {
    return (
      <>
      <div className="title">
        <h1>Optical Berry Analysis</h1>
        <p>Explore our products in optical berry analysis</p>
      </div>
      <div className="card">
        <div style={{backgroundColor: "rgba(0,0,0,0.1)"}}>
          <img src={machine1} alt="" style={{
            width: "82px",
            margin: "15px"
        }}></img></div>
        <div>
          <h1>NOS Machine 1</h1>
          <p>
            NOS Machine 1 enables powerful
            analysis of berry content in
            seconds. Simply place a box of
            berries in the machine and
            initiate the analysis from the
            touchscreen. A photo will be
            taken under controlled lighting
            conditions and will automatically
            be sent to the NOS Platform for
            an instant analysis of the
            content. See the results directly
            on the touchscreen or follow
            them up on the Web App,
            anytime and anywhere you
            want.
          </p>
        </div>
      </div>
      <div className="card">
        <div><img src={webApp} alt="" style={{width: "400px", borderRadius: "20px 20px 0 0"}}></img></div>
        <div>
          <h1>Web App</h1>
          <p>
            The Web App lets you view
            your analyses in insightful
            graphs and search the
            database with various filters.
            You can both mass export
            data in xlsx files or review
            individual analyses in more
            detail. The web app also
            makes it possible to export
            customisable quality reports
            in printable PDF format.<br></br><br></br><br></br>
          </p>
        </div>
      </div>
      <div className="title">
        <h1>Marketplace</h1>
        <p>Explore our marketplace products.</p>
      </div>
      {/* eslint-disable-next-line */}
      <a target="_blank" className="card" href="https://berryprice.com">
        <div><img src={berryPriceLogo} alt="berryprice logo" style={{width: "200px", borderRadius: "20px 20px 0 0"}}></img></div>
        <div>
          <h1>Berryprice</h1>
          <p>
            Berryprice is a marketplace for selling and buying berries
            such as lingonberries and bilberries. It has never been
            easier to find the best location to sell berries for pickers.
            It has never been easier to reach out to pickers for sellers.
            Browse daily prices, get directions and more.
            <br></br><br></br><br></br><br></br>
          </p>
          <p style={{color: "#ff9e29"}}>berryprice.com</p>
        </div>
      </a>
      </>
    );
  }
  