
import mail from "../images/mail.svg"

export default function Contact() {
    return (
      <>
      <div className="card">
        <div><img src={mail} alt="" style={{width: "80px"}}></img></div>
        <div>
          <a href="mailto:info@nostechnology.com"><h1>info@nostechnology.com</h1></a>
        </div>
      </div>
      </>
    );
  }
  