
import logo from "../images/NOS-Logo-Horizontal-Color-Black.svg";
import { Link } from "react-router-dom";
import { useState } from "react";

export default function Header() {
    const [sliderXValue, setSliderXValue] = useState()
    
    setInterval(() => {
        let { pathname } = window.location
        pathname = pathname.substring(1)
        pathname === "about" ? setSliderXValue("-100%") :
        pathname === "contact" ? setSliderXValue("100%") :
        setSliderXValue("0")
    }, 100)

    return (
        <div className="header">
            <div className="navbar-top"><img src={logo} alt="NOS Logo" className="logo" /></div>
            <div className="navbar-bottom">
                <div className="line" style={{
                    transform: `translateX(${sliderXValue})`,
                    transition: "ease 0.3s"
                }}></div>
                <div className="links">
                    <Link to="/about">About NOS</Link>
                    <Link to="/">Products</Link>
                    <Link to="/contact">Contact</Link>
                </div>
            </div>
        </div>
    )
}