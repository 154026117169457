
import About from './components/About';
import Header from './components/Header';
import Products from './components/Products';
import Contact from './components/Contact';
import Footer from './components/Footer';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

function App() {
  return (
    <>
      <Router>
        <Header/>
        <div className="main">
          <Routes>
            <Route path="/about" element={<About/>}/>
            <Route path="/" element={<Products/>}/>
            <Route path="/contact" element={<Contact/>}/>
          </Routes>
        </div>
        <Footer/>
      </Router>
    </>
  );
}

export default App;
