
import whiteLogo from "../images/white-logo.svg"
import blatand from "../images/blatand.png"
import adex from "../images/adex.png"
import { Link } from "react-router-dom"

export default function About() {
  return (
    <>
      <div className="card">
        <div className="orangeBg"><img src={whiteLogo} alt="" style={{width: "150px"}}></img></div>
        <div>
          <h1>About NOS</h1>
          <p>
            NOS develops software and hardware for
            integrated technical applications that
            streamline procurement, management, and
            other aspects within industrial
            operations. Learn more about our
            <Link to="/"> products </Link>
            or <Link to="/contact"> get in touch.</Link>
          </p>
        </div>
      </div>
      <div className="section">
        <h1>Trusted by</h1>
        <div style={{
          maxWidth: "80%",
          width: "400px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
          height: "60px"
        }}>
          <img src={blatand} alt="Blåtand AB" style={{height: "100%"}}></img>
          <img src={adex} alt="Adex LT" style={{height: "100%"}}></img>
        </div>
      </div>
    </>
  );
}
